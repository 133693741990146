import Footer from "./Footer"
import Hero from "./Hero"
import Opportunities from "./Opportunities"
import Leadership from "./Leadership"
import Mission from "./Mission"

type StreamFinancialProps = {
  domain: string
}

const StreamFinancial = ({ domain }: StreamFinancialProps) => {
  return (
    <>
      <Hero domain={domain} />
      <Opportunities domain={domain} />
      <Leadership domain={domain} />
      <Mission />
      <Footer domain={domain} />
    </>
  )
}

export default StreamFinancial
